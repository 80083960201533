import React from 'react'
import LeadsList from '../../pages/Commercial/Leads/List'
// import LeadsCreate from '../../pages/Commercial/Leads/Create'
// import LeadsUpdate from '../../pages/Commercial/Leads/Update'
// import LeadsView from '../../pages/Commercial/Leads/View'
import PrivateRoute from '../PrivateRoute'
import { CustomSwitch } from '../../components/CustomSwitch'

export const LeadsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoute path="/commercial/leads" exact component={LeadsList} />
    {/* <PrivateRoute
      path="/commercial/leads/create"
      exact
      component={LeadsCreate}
    />
    <PrivateRoute
      path="/commercial/leads/view/:id"
      exact
      component={LeadsView}
    />
    <PrivateRoute
      path="/commercial/leads/update/:id"
      exact
      component={LeadsUpdate}
    /> */}
  </CustomSwitch>
)
