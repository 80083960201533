import React, { ChangeEvent, useCallback, useRef, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Select } from '../../../../../components/Form'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiDeleteDocument } from '../../domain/api'
import { getFileNameWithOutHash } from '../../../../../utils/getFileNameWithOutHash'
import { Alert } from '../../../../../components/Alert'
import { Loading } from '../../../../../components/Loading'
import { v4 as uuidv4 } from 'uuid'
import { ENDPOINT } from '../../../../../common/constants/endpoint'
import { INSTRUCTION_FILE_OPTIONS } from '../../../../../common/constants/instruction-file'

type IsOpenInModalProps = {
  handleOnClose: () => void
}
type TypesFormProps = {
  updateDocuments?: (data: any) => void
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: {
    id: string
    finalInformation: ContractFinalInformationApiResponse[]
  }
}
export const FormAddDocument = ({
  isOpenInModal,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const inputFileRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const { updateDataTable } = useUpdateDataTable()
  const [documents, setDocuments] = useState<Documents[]>([])
  const [alert, setIsActiveAlert] =
    useState<{ isActive: boolean; document: File }>()

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = useCallback(
    async data => {
      setIsLoading(true)
      const formData = new FormData()
      const newDocuments = documents.filter(newDocument => {
        const isDuplicated = !!initialValues.finalInformation.find(
          oldDocument =>
            getFileNameWithOutHash(oldDocument.file) ===
            newDocument.archive.name
        )
        return !isDuplicated
      })
      newDocuments.forEach(document => {
        formData.append('medias', document.archive)
      })

      formData.append('instruction', data.instruction)
      formData.append('contract_id', initialValues?.id)

      if (!isOpenInModal) return
      const { handleOnClose } = isOpenInModal

      try {
        await api.post(ENDPOINT.FINAL_INFORMATION.CREATE, formData, {
          onUploadProgress: progressEvent => {
            const progress: number = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            console.log(`A imagem  est ${progress}% carregada... `)
          }
        })
        updateDataTable()

        handleOnClose()
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
      } catch (error) {
        handleOnClose()
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      } finally {
        setIsLoading(false)
      }
    },
    [
      addToast,
      documents,
      initialValues.finalInformation,
      initialValues?.id,
      isOpenInModal,
      updateDataTable
    ]
  )

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        let newDocuments = documents
        const isDuplicated = documents.find(
          document => document.archive?.name === event.target.files[0]?.name
        )
        if (isDuplicated) {
          if (inputFileRef.current) {
            inputFileRef.current.value = ''
          }
        } else {
          newDocuments = [
            ...documents,
            { archive: event.target.files[0], id: uuidv4() }
          ]
        }
        setDocuments(newDocuments)
      }
    },
    [documents]
  )

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert(oldAlert => ({
      ...oldAlert,
      isActive: false
    }))
  }

  const handleRemoveFile = useCallback(
    async (file: File) => {
      setIsLoading(true)
      if (initialValues.finalInformation.length) {
        const findDocument = initialValues.finalInformation.find(document => {
          const fileName = getFileNameWithOutHash(document.file)
          return fileName === file.name
        })
        if (findDocument) {
          try {
            await api.delete(apiDeleteDocument(findDocument.id))
            setIsLoading(false)
            addToast({
              title: 'Documento removido com sucesso',
              type: 'success'
            })
          } catch (error) {
            setIsLoading(true)
            addToast({
              title: 'Documento removido com sucesso',
              type: 'error'
            })
          }
        }
      }
      if (inputFileRef.current) {
        inputFileRef.current.value = ''
      }
      setDocuments(oldDocuments =>
        oldDocuments.filter(document => document.archive.name !== file.name)
      )
    },
    [addToast, initialValues?.finalInformation]
  )

  if (isLoading) {
    return <Loading isActive />
  }

  return (
    <>
      <Form onSubmit={onSubmitForm}>
        <>
          <div className="card">
            <div>
              <div className="row mb-5">
                <div className="col-md-6 mt-13">
                  <input
                    className="form-control form-control-solid"
                    type="file"
                    ref={inputFileRef}
                    onChange={handleFileChange}
                    required
                  />
                </div>
                <Select
                  className="col-md-3"
                  name="instruction"
                  label="Instrução"
                  options={INSTRUCTION_FILE_OPTIONS}
                  blank
                  defaultValue=""
                />
              </div>
            </div>
            <div className="card-footer d-flex justify-content-end py-6 ">
              <button type="submit" className="btn btn-primary">
                Enviar arquivo
              </button>
            </div>
          </div>
        </>
      </Form>

      {alert && alert.isActive && (
        <Alert
          message={`Tem certeza que deseja excluir esse documento ${alert.document.name} ? ele não poderá ser recuperado`}
          onClickCancellButton={handlerClickButtonCancellAlert}
          onClickConfirmButton={() => {
            handleRemoveFile(alert.document)
            setIsActiveAlert(oldAlert => ({
              ...oldAlert,
              isActive: false
            }))
          }}
          isActive={alert.isActive}
        />
      )}
    </>
  )
}
