import { Header } from '../../../../../components/DataTable'

export const headers: Header[] = [
  { name: 'Indicador', field: 'indicator.name', sortable: true },
  { name: 'Contratante', field: 'client.name', sortable: true },
  { name: 'Serviço', field: 'service.name', sortable: true },
  {
    name: 'Status de pagamento',
    field: 'status',
    sortable: true,
    custom: true
  },
  {
    name: 'Valor da comissão',
    field: 'indicator_value_comission',
    sortable: true,
    custom: true
  },
  { name: 'Criado em', field: 'created_at', sortable: true, custom: true }
]

export const headersForm: Header[] = [
  { name: 'Nome', field: 'applicant_name', sortable: true },
  { name: 'CPF', field: 'cpf', sortable: true },
  { name: 'Email', field: 'email', sortable: true },
  { name: 'Status', field: 'formPhaseProcess', sortable: true, custom: true },
  { name: 'Criado em', field: 'created_at', sortable: true, custom: true },
  {
    name: 'Ações',
    field: 'actions',
    sortable: false
  }
]

export const headersContractHistory: Header[] = [
  { name: 'Descrição', field: 'description', sortable: true },
  {
    name: 'Criado em',
    field: 'created_at',
    sortable: true,
    custom: true,
    width: 200
  },
  {
    name: 'Ações',
    field: 'actions',
    sortable: false,
    width: 100
  }
]
