import React, { useCallback, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'

import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'

import { FormContainer } from './styles'
import { DatePickerCustom as DatePicker } from '../../../../../components/Form/dateWithoutState'
import { IMaskInput } from 'react-imask'
import moment from 'moment'
import {
  FINANCIAL_MOVEMENT_PAYMENT_METHODS_OPTIONS,
  INPUT_MASK
} from '../../../../../common/constants'
import { Loading } from '../../../../../components/Loading'
import { BRL } from '../../../../../utils/mask'
type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}
type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: ContractApiResponse & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
export const FormDownloadManual = ({
  isOpenInModal,
  initialValues
}: TypesFormProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false)
  const { addToast } = useToast()
  const [date, setDate] = useState<Date | null>(null)

  const { updateDataTable } = useUpdateDataTable()

  const onSubmitForm = useCallback(
    async (data: any) => {
      try {
        try {
          setIsLoading(true)
          await api.put(
            `/financial/financialMovements/downloadManual/${initialValues?.financialMovements[0].id}`,
            {
              ...data,
              amount_charged: BRL(data.amount_charged).value,
              settlement_date: moment(
                data.settlement_date,
                INPUT_MASK.DATE_ISO_FULL
              ).format(INPUT_MASK.DATE_ISO_YEAR)
            }
          )
          updateDataTable()
          setIsLoading(false)
          isOpenInModal?.handleOnClose?.()
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (error) {
          setIsLoading(false)
          isOpenInModal?.handleOnClose?.()
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
        setIsLoading(false)
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar',
          description:
            'Ocorreu um erro ao atualizar, por favor, tente novamente.'
        })
        isOpenInModal?.handleOnClose?.()
      }
    },
    [addToast, initialValues, isOpenInModal, updateDataTable]
  )

  return (
    <>
      {isLoading && <Loading isActive />}
      <Form onSubmit={onSubmitForm}>
        <>
          <div className="card">
            <FormContainer>
              <div
                className="row mb-5"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Select
                  className="col-md-6"
                  name="send_paid_mail"
                  label="Enviar confirmação de pagamento"
                  options={[
                    {
                      name: 'Sim',
                      value: 1
                    },
                    {
                      name: 'Não',
                      value: 0
                    }
                  ]}
                  blank
                />
                <Select
                  className="col-md-6"
                  name="payment_method"
                  label="Forma de pagamento"
                  options={FINANCIAL_MOVEMENT_PAYMENT_METHODS_OPTIONS}
                  blank
                />
                <Input
                  className="col-md-6"
                  name="amount_charged"
                  label="Valor pago"
                  price
                />
                <DatePicker
                  name="settlement_date"
                  className="col-md-6"
                  label="Data de liquidação"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/AAAA"
                  customInput={
                    <IMaskInput
                      mask={Date}
                      pattern={'d/m/Y'}
                      format={date => {
                        return moment(date).format(INPUT_MASK.DATE_LOCALE)
                      }}
                      parse={value => {
                        return moment(value, INPUT_MASK.DATE_LOCALE).toDate()
                      }}
                    />
                  }
                  onChange={value => setDate(value)}
                  selected={date}
                  controlled
                />
              </div>
            </FormContainer>
            <div className="card-footer d-flex justify-content-end py-6 px-9 pe-0">
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </div>
        </>
      </Form>
    </>
  )
}
