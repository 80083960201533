import React, { useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input } from '../../../../../components/Form'

import { useToast } from '../../../../../hooks/toast'

import { FormContainer } from './styles'
import { Date as DatePicker } from '../../../../../components/Form/date'
import { Loading } from '../../../../../components/Loading'
import { StringParam, useQueryParam } from 'use-query-params'
type IsOpenInModalProps = {
  handleOnClose: () => void
}

type OperationIndexData = {
  id?: string
  title?: string
  index?: string
  number_installments?: string
}

type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: OperationIndexData & {
    idUpdate: number
  }
}
export const FormPaidInvoice = ({
  isOpenInModal
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState<Date>()
  const [invoiceIds] = useQueryParam('ids', StringParam)

  const onSubmitForm = async (data: { paid_date: string; file?: FileList }) => {
    setIsLoading(true)
    const formData = new FormData()

    if (data?.file?.[0]) {
      formData.append('file', data.file[0])
    }
    formData.append('ids', invoiceIds)
    formData.append('paid_date', data.paid_date)
    try {
      await api.patch(
        '/financial/invoiceCommissionIndicators/updatePaidDate',
        formData
      )
      addToast({
        title: 'Fatura atualizada com sucesso',
        description: 'A fatura foi atualizada com sucesso',
        type: 'success'
      })
    } catch (err) {
      console.log(err)
      addToast({
        title: 'Erro ao atualizar a fatura',
        description: 'Ocorreu um erro ao atualizar a fatura',
        type: 'error'
      })
    } finally {
      setIsLoading(false)
      isOpenInModal?.handleOnClose?.()
    }
  }

  if (isLoading) {
    return <Loading isActive />
  }

  return (
    <Form onSubmit={onSubmitForm}>
      <>
        <div className="card mb-5 mb-xl-10">
          <FormContainer className="form">
            <div className="row mb-5">
              <DatePicker
                name="paid_date"
                label="Data de pagamento"
                onChange={date => setStartDate(date)}
                selected={startDate}
                controlled
                required
              />
            </div>
            <div className="row mb-5">
              <Input
                name="file"
                label="Arquivo"
                type="file"
                accept="image/png"
              />
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
