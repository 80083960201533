type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

type PortletTitleCrudType = {
  create: TypeActionNameWithTo
  read: TypeActionNameWithTo
  readLeads: TypeActionNameWithTo
  update: TypeActionNameWithTo
  delete: TypeActionNameWithTo
}
export type LeadData = {
  cpf: string
  created_at: string
  deleted_at?: string
  email: string
  id: number
  name: string
  status: string
  type: string
  values?: string
  updated_at: string
}

export type LeadsData = {
  id: number
  cpf: string
  name: string
  payment_form: string
  status: string
  values: number
  created_at: string
  updated_at: string
  deleted_at?: string
}

const nameEntity = 'Lead'
const nameSource = 'commercial/leads'
const namePageTitle = 'Leades'
const nameActionPageMain: TypeActionNameWithTo = {
  name: 'Leades',
  to: '/commercial/leads',
  icon: ''
}

const nameActions: PortletTitleCrudType = {
  create: {
    name: 'Adicionar',
    to: '/commercial/leads/create/',
    icon: 'fa fa-plus'
  },
  read: {
    name: 'Visualizar',
    to: '/commercial/leads/',
    icon: 'fa fa-list'
  },
  readLeads: {
    name: 'Contratos',
    to: '/commercial/leads/',
    icon: 'fa fa-list'
  },
  update: {
    name: 'Editar',
    to: '/commercial/leads/update/',
    icon: 'fa fa-edit'
  },
  delete: {
    name: 'Remover',
    to: '#!',
    icon: 'fa fa-remove'
  }
}

export {
  nameEntity,
  nameSource,
  namePageTitle,
  nameActionPageMain,
  nameActions
}
