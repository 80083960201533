import { Redirect, Route as ReactRouter, Switch } from 'react-router-dom'
import { NotFoundPage } from '../pages/NotFound'
import Dashboard from '../pages/Dashboard'
import SignIn from '../pages/Signin'
import { MenusRouter } from './menus/MenusRouter'
import { useAuth } from '../hooks/auth'
import { ForgotPassword } from '../pages/ForgotPassword'
import { UsersRouter } from './users/UsersRouter'
import { FormsRouter } from './forms/FormsRouter'
import PrivateRoutes from './PrivateRoute'
import { ContractsRouter } from './commercial/ContractsRouter'
import { PhaseProcessRouter } from './commercial/PhaseProcessRouter'
import { ServicesRouter } from './commercial/ServicesRouter'
import { ServiceCategoriesRouter } from './commercial/ServiceCategoriesRouter'
import { ContractFormsRouter } from './commercial/FormsRouter'
import { ContractProgressRouter } from './commercial/ContractProcessRouter'
import { TutorialsRouter } from './commercial/TutorialsRouter'
import { FormTypesRouter } from './commercial/FormTypesRouter'
import { ClientsRouter } from './commercial/ClientsRouter'
import { ComissionsRouter } from './commercial/ComissionsRouter'
import { InvoiceCommissionIndicatorsRouter } from './financial/InvoiceCommissionIndicatorsRouter'
import { LeadsRouter } from './commercial/LeadsRouter'

const Routes: React.FC = () => {
  const { userLogged, backupHistory } = useAuth()
  return (
    <Switch>
      <ReactRouter path="/" exact component={SignIn}>
        {!backupHistory.length && userLogged() && <Redirect to="/dashboard" />}
      </ReactRouter>
      <PrivateRoutes path="/dashboard" exact component={Dashboard} />
      <ReactRouter path="/forms" component={FormsRouter} />
      <ReactRouter path="/users" component={UsersRouter} />
      <ReactRouter path="/commercial/services" component={ServicesRouter} />
      <ReactRouter
        path="/commercial/serviceCategories"
        component={ServiceCategoriesRouter}
      />
      <ReactRouter
        path="/commercial/contracts/forms"
        component={ContractFormsRouter}
      />
      <ReactRouter path="/commercial/clients" component={ClientsRouter} />
      <ReactRouter path="/commercial/leads" component={LeadsRouter} />
      <ReactRouter path="/commercial/tutorials" component={TutorialsRouter} />
      <ReactRouter path="/commercial/contracts" component={ContractsRouter} />
      <ReactRouter path="/commercial/comissions" component={ComissionsRouter} />
      <ReactRouter
        path="/financial/invoiceCommissionIndicators"
        component={InvoiceCommissionIndicatorsRouter}
      />
      <ReactRouter path="/commercial/formTypes" component={FormTypesRouter} />
      <ReactRouter
        path="/commercial/phasesProcess"
        component={PhaseProcessRouter}
      />
      <ReactRouter
        path="/commercial/contractProgress"
        component={ContractProgressRouter}
      />
      <ReactRouter path="/menus" component={MenusRouter} />
      <ReactRouter path="/forgot" component={ForgotPassword} />
      <ReactRouter path="*" component={NotFoundPage} />
    </Switch>
  )
}
export default Routes
