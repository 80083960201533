import React, { CSSProperties } from 'react'
import ReactTooltip from 'react-tooltip'

import { Container, JokerIcon } from './style'

type TypeTooltipComponent = {
  message: string
  label: string
  bold?: boolean
  classname?: string
  container?: {
    classname: string
  }
  options?: {
    icon: {
      styles: CSSProperties
    }
  }
}

export const TooltipComponent = ({
  message,
  label,
  bold,
  classname,
  options,
  container
}: TypeTooltipComponent): JSX.Element => {
  return (
    <Container bold={bold} className={classname}>
      <label htmlFor="form">{label}</label>
      <div className={container?.classname || 'ms-sm-5'}>
        <span data-tip={message}>
          <JokerIcon style={{ ...options?.icon?.styles }} />
        </span>
        <ReactTooltip place="top" type="dark" effect="float" html />
      </div>
    </Container>
  )
}
