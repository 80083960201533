import { Header } from '../../../../../components/DataTable'

export const headers: Header[] = [
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'CPF', field: 'cpf', sortable: true },
  { name: 'Nascimento', field: 'birth_date', sortable: true, custom: true },
  { name: 'Email', field: 'email', sortable: true },
  { name: 'Celular', field: 'cell_phone', sortable: true },
  { name: 'Agencia', field: 'agency_name', sortable: true },
  { name: 'Aprovado', field: 'status', sortable: true, custom: true },
  { name: 'Criado em', field: 'created_at', sortable: true, custom: true },
  {
    name: 'Ações',
    field: 'actions',
    sortable: false
  }
]
