import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import Form, { Input } from '../../components/Form'
import { useToast } from '../../hooks/toast'
import api from '../../services/api'
interface ForgotPasswordFormData {
  password: string
  password_confirmation: string
}
export const ForgotPassword: React.FC = () => {
  const [token] = useQueryParam('token', StringParam)
  const { addToast } = useToast()
  const history = useHistory()
  const onSubmit = useCallback(
    async ({ password, password_confirmation }: ForgotPasswordFormData) => {
      try {
        await api.post('password/reset', {
          token,
          password,
          password_confirmation
        })
        addToast({
          type: 'success',
          title: 'Senha alterada',
          description: 'Sua senha foi alterada com sucesso.'
        })
        history.push('/')
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao altera a senha',
          description: 'Não foi possivel alterar a senha.'
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addToast, history]
  )
  return (
    <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <a href="#" className="mb-12">
          {/* <img
            alt="Logo"
            src={logoDark}
            className="h-150px w-400px"
            style={{ objectFit: 'cover' }}
          /> */}
        </a>
        <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Form className="form w-100" onSubmit={onSubmit}>
            <div className="fv-row">
              <Input
                className="placeholder-no-fix input"
                autoComplete="off"
                type="password"
                label="Nova senha"
                name="password"
                rules={{ required: true }}
              />
            </div>

            <div className="fv-row mb-10">
              <Input
                className="placeholder-no-fix"
                type="password"
                autoComplete="off"
                label="Confirme a senha"
                name="password_confirmation"
                rules={{ required: true }}
              />
            </div>

            <div className="text-center">
              <button
                type="submit"
                id="kt_sign_in_submit"
                className="btn btn-lg btn-primary w-100 mb-5"
              >
                <span className="indicator-label">Continuar</span>
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}
