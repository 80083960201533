import React, { useCallback, useEffect, useState } from 'react'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../components/Container'
import { BRL } from '../../../../utils/mask'
import moment from 'moment'
import { INPUT_MASK } from '../../../../common/constants'
import {
  INVOICE_COMMISSION_INDICATOR_SITUATION_ENUM,
  INVOICE_COMMISSION_INDICATOR_SITUATION_OPTIONS
} from '../../../../common/constants/Invoice-commission-indicator'
import { Loading } from '../../../../components/Loading'
import CustomDataTable from '../components/CustomDataTable'
import Modal from '../../../../components/Modal'
import { FormPaidInvoice } from '../components/Form'
import { useHistory } from 'react-router-dom'
import Form, { Select } from '../../../../components/Form'
import { StringParam, useQueryParam } from 'use-query-params'
import { useUpdateDataTable } from '../../../../hooks/dataTable'
import { Date as DatePicker } from '../../../../components/Form/date'
import api from '../../../../services/api'

const OperationIndexList = (): JSX.Element => {
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [isLoading, setIsLoading] = useState(false)
  const [isDownloadLoading, setIsDownloadLoading] = useState(false)
  const [openModalPaidInvoice, setOpenModalPaidInvoice] = useState(false)
  const [indicatorOptions, setIndicatorOptions] = useState([])
  const [clearFilter, setClearFilter] = useState<any>()
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [searchParameters, setSearchParameters] = useState<any[]>([])
  const [invoiceIds] = useQueryParam('ids', StringParam)

  const handleCloseModal = useCallback(() => {
    setIsLoading(true)
    setOpenModalPaidInvoice(false)
    updateDataTable()
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [updateDataTable])

  const handleSetFilter = useCallback(data => {
    setIsLoading(true)
    setSearchParameters([{ ...data }])
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [])

  const handleClearFilter = useCallback(() => {
    setClearFilter(true)
    setStartDate(null)
    setEndDate(null)
    setSearchParameters([])
    setTimeout(() => {
      setClearFilter(false)
    }, 500)
  }, [])
  const handleLoadIndicatorOptions = useCallback(async () => {
    try {
      const response = await api.get('/commercial/indicators/options')
      setIndicatorOptions(
        response.data.map((item: { id: number; name: string }) => ({
          name: item.name,
          value: item.id
        }))
      )
    } catch (error) {}
  }, [])

  useEffect(() => {
    handleLoadIndicatorOptions()
  }, [handleLoadIndicatorOptions])

  const handleDownload = useCallback(async (item: { id: number }) => {
    const currentDate = moment().format('YYYY-MM-DD')
    setIsDownloadLoading(true)
    try {
      const responseFile = await api.get(
        `/financial/invoiceCommissionIndicators/download/${item.id}`,
        {
          responseType: 'blob'
        }
      )
      const contentDisposition = responseFile.headers['Content-Disposition']
      let filename = `${currentDate}-fatura.pdf` // Valor padrão caso não seja encontrado no cabeçalho
      if (contentDisposition && contentDisposition.includes('attachment')) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/)
        if (filenameMatch.length > 1) {
          filename = filenameMatch[1]
        }
      }
      const url = window.URL.createObjectURL(new Blob([responseFile.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } catch (error) {
      console.log(error)
    } finally {
      setIsDownloadLoading(false)
    }
  }, [])

  if (clearFilter || isLoading || isDownloadLoading) {
    return <Loading isActive />
  }

  return (
    <>
      <Container
        pageTitle="Listagem"
        breadcrumb={breadcrumbList}
        tools={toolsList}
      >
        <Form
          onSubmit={handleSetFilter}
          defaultValues={{
            client_indicator_id: searchParameters?.[0]?.client_indicator_id,
            start_date: startDate,
            end_date: endDate,
            situation: searchParameters?.[0]?.situation
          }}
        >
          <div className="form">
            <div className="row mb-5">
              <Select
                className="col-md-3"
                name="client_indicator_id"
                label="Indicador"
                options={indicatorOptions}
                blank
                defaultValue=""
              />
              <div className="col-md-3">
                <DatePicker
                  name="start_date"
                  label="Inicio"
                  onChange={date => setStartDate(date)}
                  selected={startDate}
                  controlled
                />
              </div>
              <div className="col-md-3">
                <DatePicker
                  name="end_date"
                  label="Final"
                  onChange={date => setEndDate(date)}
                  selected={endDate}
                  controlled
                />
              </div>
              <Select
                className="col-md-3"
                name="situation"
                label="Status"
                options={INVOICE_COMMISSION_INDICATOR_SITUATION_OPTIONS}
                blank
                defaultValue=""
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {!!invoiceIds?.length && (
              <div className="col-md-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-light-primary"
                  onClick={() => {
                    setOpenModalPaidInvoice(true)
                  }}
                >
                  Pagar faturas
                </button>
              </div>
            )}
            <div className="col-md-1 d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-light-primary"
                onClick={handleClearFilter}
              >
                Limpar
              </button>
            </div>
            <div className="col-md-1 d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Filtrar
              </button>
            </div>
          </div>
        </Form>
        <CustomDataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'id' }}
          notHasChildren
          headers={headers}
          customHeaders={[
            {
              name: 'Valor',
              field: 'value_comission',
              sortable: false,
              element: item => {
                return <p>R$ {BRL(Number(item.value_comission)).format()}</p>
              }
            },
            {
              name: 'Valor pago',
              field: 'paid_value',
              sortable: false,
              element: item => {
                return <p>R$ {BRL(Number(item.paid_value)).format()}</p>
              }
            },
            {
              name: 'Pago em',
              field: 'paid_date',
              sortable: false,
              element: item => {
                if (!item.paid_date) {
                  return <p></p>
                }
                return (
                  <p>
                    {moment(item.paid_date, INPUT_MASK.DATE_ISO_FULL).format(
                      INPUT_MASK.DATE_LOCALE
                    )}
                  </p>
                )
              }
            },
            {
              name: 'Criado em',
              field: 'created_at',
              sortable: false,
              element: item => {
                if (!item.created_at) {
                  return <p></p>
                }
                return (
                  <p>
                    {moment(item.created_at, INPUT_MASK.DATE_ISO_FULL).format(
                      INPUT_MASK.DATE_LOCALE
                    )}
                  </p>
                )
              }
            },
            {
              name: 'Situação',
              field: 'situation',
              sortable: false,
              element: item => {
                if (!item.situation) {
                  return <p></p>
                }
                return (
                  <p>
                    {
                      INVOICE_COMMISSION_INDICATOR_SITUATION_ENUM[
                        item.situation
                      ]
                    }
                  </p>
                )
              }
            },
            {
              name: 'Ações',
              field: 'actions',
              sortable: false,
              element: item => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#0095E8'
                    }}
                  >
                    <span
                      className="fa fa-download"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDownload(item)}
                    ></span>
                  </div>
                )
              }
            }
          ]}
          searchParameters={searchParameters}
          // actions={[
          //   {
          //     name: 'Baixa manual',
          //     title: 'Baixa manual',
          //     spanIcon: 'fa-regular fa-dollar  fa-lg me-5',
          //     onClick: (item: ContractApiResponse) => {
          //       setOpenModalPaidInvoice(true)
          //     }
          //   }
          // ]}
          enableCheckbox
          onSelectedRow={item => {
            history.replace(
              `/financial/invoiceCommissionIndicators?ids=${item}`
            )
          }}
        />
      </Container>

      {openModalPaidInvoice && (
        <Modal
          onClickButtonCancel={handleCloseModal}
          isOpenModal={openModalPaidInvoice}
          pageTitle={'Pagar fatura'}
          Children={
            <FormPaidInvoice
              isOpenInModal={{
                handleOnClose: handleCloseModal
              }}
            />
          }
        />
      )}
    </>
  )
}

export default OperationIndexList
