import React from 'react'
import InvoiceCommissionIndicatorsList from '../../pages/Financial/InvoiceCommissionIndicator/List'
import PrivateRoute from '../PrivateRoute'
import { CustomSwitch } from '../../components/CustomSwitch'

export const InvoiceCommissionIndicatorsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoute
      path="/financial/invoiceCommissionIndicators"
      exact
      component={InvoiceCommissionIndicatorsList}
    />
  </CustomSwitch>
)
