import styled, { css } from 'styled-components'
import { BsFillQuestionSquareFill } from 'react-icons/bs'

export const Container = styled.div<{ bold?: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  /* z-index: 100; */
  label {
    ${({ bold }) =>
      bold &&
      css`
        font-weight: bold;
      `}
  }
  > div {
    cursor: help;
    span {
      cursor: help;
    }
  }
`

export const JokerIcon = styled(BsFillQuestionSquareFill)`
  color: #f0ad4e;
  width: 24px;
  height: 24px;
`
