import React, { useCallback, useEffect, useState } from 'react'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import Container from '../../../../components/Container'
import moment from 'moment'
import { INPUT_MASK } from '../../../../common/constants'
import { toolsList } from '../domain/tools'
import api from '../../../../services/api'
import Form, { Select } from '../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import { Loading } from '../../../../components/Loading'
import { phoneMask } from '../../../../utils/mask'
const LeadsList: React.FC = () => {
  const history = useHistory()
  const [title, setTitle] = useQueryParam('title', StringParam)
  const [statusQuery] = useQueryParam('status', StringParam)
  const [searchParameters, setSearchParameters] = useState<any[]>(undefined)
  // const handleExport = useCallback(async () => {
  //   let getUrl = '/commercial/leads/export?'
  //   if (activeQuery) {
  //     getUrl += `active=${activeQuery}`
  //   }
  //   try {
  //     const response = await api.get(getUrl)
  //     const blob = new Blob(['\ufeff' + response.data], {
  //       type: 'text/csv;charset=utf-8"'
  //     })
  //     const url = window.URL.createObjectURL(blob)
  //     const a = document.createElement('a')
  //     a.download = 'relatório-contratacoes.csv'
  //     a.href = url
  //     document.body.appendChild(a)
  //     a.click()
  //     document.body.removeChild(a)
  //   } catch (error) {}
  // }, [activeQuery])

  const handleFilter = useCallback((data: { travel_agency: number }) => {
    console.log(data)
    setSearchParameters([data])
  }, [])

  const handleClearFilter = useCallback(async () => {
    // setTravelAgency(null)
  }, [])
  useEffect(() => {
    setSearchParameters([
      {
        status: statusQuery
      }
    ])
  }, [statusQuery])

  // if (!searchParameters) {
  //   return <Loading isActive />
  // }

  return (
    <>
      <Container
        pageTitle={title || 'Listagem'}
        breadcrumb={breadcrumbList}
        tools={toolsList}
      >
        <Form onSubmit={handleFilter}>
          <div className="form">
            <div className="row mb-5">
              <Select
                className="col-md-3"
                name="travel_agency"
                label="Agencia de viagem"
                options={[
                  {
                    name: 'SIM',
                    value: 1
                  },
                  {
                    name: 'NÃO',
                    value: 0
                  }
                ]}
                blank
                defaultValue=""
              />
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <div className="col-md-1 d-flex justify-content-end">
              <button
                type="reset"
                className="btn btn-light-primary"
                onClick={handleClearFilter}
              >
                Limpar
              </button>
            </div>
            <div className="col-md-1 d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Filtrar
              </button>
            </div>
          </div>
        </Form>

        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'created_at' }}
          headers={headers}
          notHasChildren
          searchParameters={searchParameters}
          customHeaders={[
            {
              name: 'Celular',
              field: 'phone',
              sortable: true,
              element: item => {
                if (!item?.phone) {
                  return <p></p>
                }
                return <p>{phoneMask(item?.phone)}</p>
              }
            },
            {
              name: 'Criado em',
              field: 'created_at',
              sortable: false,
              element: item => {
                return (
                  <p>
                    {moment(item?.created_at).format(
                      INPUT_MASK.DATE_LOCALE_WITH_TIME
                    )}
                  </p>
                )
              }
            }
          ]}
        />
      </Container>
    </>
  )
}

export default LeadsList
