import styled from 'styled-components'

export const Container = styled.div`
  .fs-medium {
    font-size: 1.5rem;
  }

  .container-card {
    border-radius: 5px 5px 0px 0px;
  }
`
