import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'

import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { BRL } from '../../../../../utils/mask'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}
type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: ContractApiResponse & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
export const FormSetCommission = ({
  isOpenInModal,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()

  const onSubmitForm = useCallback(
    async (data: any) => {
      try {
        try {
          activeLoading()
          await api.put(
            `/commercial/contracts/updateCommission/${initialValues?.id}`,
            {
              indicator_percent_comission: BRL(data.indicator_percent_comission)
                .value
            }
          )
          disableLoading()
          isOpenInModal?.handleOnClose?.()
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (error) {
          disableLoading()
          isOpenInModal?.handleOnClose?.()
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
        disableLoading()
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar',
          description:
            'Ocorreu um erro ao atualizar, por favor, tente novamente.'
        })
        isOpenInModal?.handleOnClose?.()
      }
    },
    [activeLoading, addToast, disableLoading, initialValues, isOpenInModal]
  )

  return (
    <Form onSubmit={onSubmitForm}>
      <div className="card">
        <div
          className="row mb-5"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Input
            name="indicator_percent_comission"
            label="% comissão"
            className="col-md"
            price
          />
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9 pe-0">
          <button type="submit" className="btn btn-primary">
            Salvar
          </button>
        </div>
      </div>
    </Form>
  )
}
