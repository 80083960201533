import React from 'react'
import { CustomSwitch } from '../../components/CustomSwitch'
import { Route } from 'react-router-dom'
import Passport from '../../pages/Forms/Passport'
import Canada from '../../pages/Forms/Canada'
import Mexico from '../../pages/Forms/Mexico'
import { American } from '../../pages/Forms/American'

export const FormsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/forms/passport" exact component={Passport} />
    <Route path="/forms/canada" exact component={Canada} />
    <Route path="/forms/mexico" exact component={Mexico} />
    <Route path="/forms/american" exact component={American} />
  </CustomSwitch>
)
