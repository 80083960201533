import React, { useCallback, useEffect, useState } from 'react'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import Container from '../../../../components/Container'
import moment from 'moment'
import { INPUT_MASK } from '../../../../common/constants'
import { toolsList } from '../domain/tools'
import api from '../../../../services/api'
import { Select } from '../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import { Loading } from '../../../../components/Loading'
import { CLIENT_STATUS_ENUM } from '../../../../common/constants/client-status'
const ClientsList: React.FC = () => {
  const history = useHistory()
  const [activeQuery] = useQueryParam('active', StringParam)
  const [travelAgencyQuery] = useQueryParam('travel_agency', StringParam)
  const [statusQuery] = useQueryParam('status', StringParam)
  const [indicatorCodeQuery] = useQueryParam('indicator_code', StringParam)
  const [searchParameters, setSearchParameters] = useState<any[]>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const handleExport = useCallback(async () => {
    let getUrl = '/commercial/clients/export?'
    if (activeQuery) {
      getUrl += `active=${activeQuery}&`
    }
    if (travelAgencyQuery) {
      getUrl += `travel_agency=${travelAgencyQuery}&`
    }
    if (statusQuery) {
      getUrl += `status=${statusQuery}`
    }
    try {
      const response = await api.get(getUrl)
      const blob = new Blob(['\ufeff' + response.data], {
        type: 'text/csv;charset=utf-8"'
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.download = 'relatório-contratacoes.csv'
      a.href = url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } catch (error) {}
  }, [activeQuery, statusQuery, travelAgencyQuery])

  const handleSetActiveFilter = useCallback(
    ({
      active = '',
      travelAgency = '',
      indicatorCode = '',
      status = ''
    }: {
      active: string
      indicatorCode?: string
      travelAgency: string
      status: string
    }) => {
      history.replace(
        `/commercial/clients?active=${
          active || activeQuery || ''
        }&travel_agency=${travelAgency || travelAgencyQuery || ''}&status=${
          status || statusQuery || ''
        }&indicator_code=${indicatorCode || indicatorCodeQuery || ''}`
      )
    },
    [activeQuery, history, statusQuery, travelAgencyQuery, indicatorCodeQuery]
  )

  const handleClearFilter = useCallback(async () => {
    history.replace('/commercial/clients')
  }, [history])

  useEffect(() => {
    setIsLoading(true)
    if (
      !activeQuery &&
      !travelAgencyQuery &&
      !statusQuery &&
      !indicatorCodeQuery
    ) {
      setSearchParameters([])
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
      return
    }
    setSearchParameters([
      {
        active: activeQuery,
        indication_code: indicatorCodeQuery,
        travel_agency: travelAgencyQuery,
        status: statusQuery
      }
    ])

    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [activeQuery, travelAgencyQuery, statusQuery, indicatorCodeQuery])

  if (!searchParameters) {
    return <Loading isActive />
  }

  if (isLoading) {
    return <Loading isActive />
  }

  return (
    <>
      <Container
        pageTitle="Listagem"
        breadcrumb={breadcrumbList}
        tools={toolsList}
      >
        <div className="form">
          <div className="row mb-5">
            <Select
              className="col-md-3"
              name="active"
              label="Ativo"
              options={[
                {
                  name: 'SIM',
                  value: 1
                },
                {
                  name: 'NÃO',
                  value: 0
                }
              ]}
              blank
              defaultValue=""
              value={activeQuery || ''}
              onChange={event => {
                handleSetActiveFilter({
                  active: event.target.value,
                  status: '',
                  travelAgency: ''
                })
              }}
            />
            <Select
              className="col-md-3"
              name="indication_code"
              label="Indicador"
              options={[
                {
                  name: 'SIM',
                  value: 1
                },
                {
                  name: 'NÃO',
                  value: 0
                }
              ]}
              blank
              defaultValue=""
              value={indicatorCodeQuery || ''}
              onChange={event => {
                handleSetActiveFilter({
                  active: '',
                  travelAgency: '',
                  indicatorCode: event.target.value,
                  status: ''
                })
              }}
            />
            <Select
              className="col-md-3"
              name="travel_agency"
              label="Agencia de viagem"
              options={[
                {
                  name: 'SIM',
                  value: 1
                },
                {
                  name: 'NÃO',
                  value: 0
                }
              ]}
              blank
              defaultValue=""
              value={travelAgencyQuery || ''}
              onChange={event => {
                handleSetActiveFilter({
                  active: '',
                  travelAgency: event.target.value,
                  status: ''
                })
              }}
            />
            <Select
              className="col-md-3"
              name="status"
              label="Aprovado"
              options={[
                {
                  name: 'SIM',
                  value: 'approved'
                },
                {
                  name: 'NÃO',
                  value: 'refused'
                }
              ]}
              blank
              defaultValue=""
              value={statusQuery || ''}
              onChange={event => {
                handleSetActiveFilter({
                  active: '',
                  status: event.target.value,
                  travelAgency: ''
                })
              }}
            />
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <div className="col-md-1 d-flex justify-content-end">
            <button
              type="reset"
              className="btn btn-light-primary"
              onClick={handleClearFilter}
            >
              Limpar
            </button>
          </div>
          <div className="col-md-1 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleExport}
            >
              Exportar
            </button>
          </div>
        </div>

        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'created_at' }}
          headers={headers}
          notHasChildren
          searchParameters={searchParameters}
          customHeaders={[
            {
              name: 'Nascimento',
              field: 'birth_date',
              sortable: true,
              element: item => {
                if (!item?.birth_date) {
                  return <p></p>
                }
                return (
                  <p>
                    {moment(item?.birth_date).format(INPUT_MASK.DATE_LOCALE)}
                  </p>
                )
              }
            },
            {
              name: 'Criado em',
              field: 'created_at',
              sortable: false,
              element: item => {
                return (
                  <p>
                    {moment(item?.created_at).format(
                      INPUT_MASK.DATE_LOCALE_WITH_TIME
                    )}
                  </p>
                )
              }
            },
            {
              name: 'Aprovado',
              field: 'status',
              sortable: true,
              element: item => {
                if (!item.status) {
                  return <p></p>
                }
                return (
                  <p>
                    {CLIENT_STATUS_ENUM[item.status as 'approved' | 'refused']}
                  </p>
                )
              }
            }
          ]}
        />
      </Container>
    </>
  )
}

export default ClientsList
