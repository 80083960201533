import moment from 'moment'
import { formatsAvailable } from '../common/constants'

export const checkFormatDates = (value: string) => {
  let formatValue
  formatsAvailable.forEach(format => {
    if (typeof value === 'object') {
      return null
    }
    if (moment(value, format, true).isValid()) {
      formatValue = format
    }
  })
  return formatValue
}
