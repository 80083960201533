import React, { useCallback, useEffect, useState } from 'react'

import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import Container from '../../../../components/Container'
import { StringParam, useQueryParam } from 'use-query-params'
import Form, { Select } from '../../../../components/Form'
import { Date as DatePicker } from '../../../../components/Form/date'
import { CONTRACTS_STATUS_PAYMENT_TRANSLATED } from '../../../../common/constants/status-contract'
import { genericMaskWithTwoZeroWithPoint } from '../../../../utils/mask'
import { useHistory } from 'react-router-dom'
import { useUpdateDataTable } from '../../../../hooks/dataTable'
import api from '../../../../services/api'
import { Loading } from '../../../../components/Loading'
import moment from 'moment'
import { INPUT_MASK } from '../../../../common/constants'
import CustomDataTable from '../components/CustomDataTable'
import { useToast } from '../../../../hooks/toast'

const ClientList: React.FC = () => {
  const history = useHistory()
  const { addToast } = useToast()
  const { updateDataTable } = useUpdateDataTable()
  const [contractIds] = useQueryParam('ids', StringParam)
  const [clearFilter, setClearFilter] = useState<any>()
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [isLoading, setIsLoading] = useState(false)
  const [indicatorOptions, setIndicatorOptions] = useState([])

  const [searchParameters, setSearchParameters] = useState<any[]>([])

  const handleSetFilter = useCallback(data => {
    setIsLoading(true)
    setSearchParameters([{ ...data }])
    setStartDate(null)
    setEndDate(null)
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [])
  const handleClearFilter = useCallback(() => {
    setClearFilter(true)
    setStartDate(null)
    setEndDate(null)
    setSearchParameters([])
    setTimeout(() => {
      setClearFilter(false)
    }, 500)
  }, [])

  const handleCreateInvoice = useCallback(async () => {
    setIsLoading(true)
    try {
      await api.post('/financial/invoiceCommissionIndicators', {
        contractIds: contractIds.split(',').map(item => Number(item))
      })
      updateDataTable()
      addToast({
        title: 'Faturas geradas com sucesso',
        type: 'success'
      })
    } catch (error) {
      addToast({
        title: 'Erro ao gerar faturas',
        type: 'error'
      })
    } finally {
      setIsLoading(false)
    }
  }, [addToast, contractIds, updateDataTable])

  const handleLoadIndicatorOptions = useCallback(async () => {
    try {
      const response = await api.get('/commercial/indicators/options')
      setIndicatorOptions(
        response.data.map((item: { id: number; name: string }) => ({
          name: item.name,
          value: item.id
        }))
      )
    } catch (error) {}
  }, [])

  useEffect(() => {
    handleLoadIndicatorOptions()
  }, [handleLoadIndicatorOptions])

  if (clearFilter) {
    return <Loading isActive />
  }

  return (
    <>
      <Loading isActive={isLoading} />
      <Container
        pageTitle="Gerar faturas"
        breadcrumb={breadcrumbList}
        tools={[]}
      >
        <Form onSubmit={handleSetFilter}>
          <div className="form">
            <div className="row mb-5">
              <Select
                className="col-md-3"
                name="client_indicator_id"
                label="Indicador"
                options={indicatorOptions}
                blank
                defaultValue=""
              />
              <div className="col-md-3">
                <DatePicker
                  name="start_date"
                  label="Inicio"
                  onChange={date => setStartDate(date)}
                  selected={startDate}
                  controlled
                />
              </div>
              <div className="col-md-3">
                <DatePicker
                  name="end_date"
                  label="Final"
                  onChange={date => setEndDate(date)}
                  selected={endDate}
                  controlled
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {!!contractIds?.length && (
              <div className="col-md-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-light-primary"
                  onClick={handleCreateInvoice}
                >
                  Gerar faturas
                </button>
              </div>
            )}
            <div className="col-md-1 d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-light-primary"
                onClick={handleClearFilter}
              >
                Limpar
              </button>
            </div>
            <div className="col-md-1 d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Filtrar
              </button>
            </div>
          </div>
        </Form>
        {!isLoading && (
          <CustomDataTable
            source={nameSource}
            entity={nameEntity}
            format={{ orderBy: '' }}
            headers={headers}
            notHasChildren
            hasSearch={false}
            searchParameters={searchParameters}
            customHeaders={[
              {
                name: 'Criado em',
                field: 'created_at',
                sortable: false,
                element: item => {
                  return (
                    <p>
                      {moment(item?.created_at).format(
                        INPUT_MASK.DATE_LOCALE_WITH_TIME
                      )}
                    </p>
                  )
                }
              },
              {
                name: 'Status',
                field: 'status',
                sortable: true,
                element: (item: ContractApiResponse) => {
                  return (
                    <p>{CONTRACTS_STATUS_PAYMENT_TRANSLATED[item.status]}</p>
                  )
                }
              },
              {
                name: 'Valor da comissão',
                field: 'indicator_value_comission',
                sortable: true,
                element: (item: { indicator_value_comission: string }) => {
                  if (!item.indicator_value_comission) {
                    return <p>0,00</p>
                  }
                  return (
                    <p>
                      {genericMaskWithTwoZeroWithPoint(
                        item.indicator_value_comission
                      )}
                    </p>
                  )
                }
              }
            ]}
            enableCheckbox
            onSelectedRow={item => {
              history.replace(`/commercial/comissions?ids=${item}`)
            }}
          />
        )}
      </Container>
    </>
  )
}

export default ClientList
