export const INVOICE_COMMISSION_INDICATOR_SITUATION_ENUM: Record<
  string,
  string
> = {
  awaiting_payment: 'Aguardando pagamento',
  paid: 'Pago',
  cancelled: 'Cancelado'
}
export const INVOICE_COMMISSION_INDICATOR_SITUATION_OPTIONS = Object.entries(
  INVOICE_COMMISSION_INDICATOR_SITUATION_ENUM
).map(([key, value]) => ({
  name: value,
  value: key
}))
