import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../services/api'
import TableHeader from './Header'
import Pagination from './Pagination'
import Search from './Search'
import { LinkContainer } from './style'
import { useUpdateDataTable } from '../../hooks/dataTable'
import { useLoading } from '../../hooks/loading'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

interface Action {
  name: string
  icon?: IconDefinition
  spanIcon?: string
  htmlIcon?: JSX.Element
  element?: (item: any) => JSX.Element
  title: string
  link?: string
  onClick?: (params: any) => void
  linkTo?: (params: any) => string
}

export interface Header {
  name: string
  field: string
  sortable: boolean
  custom?: boolean
  width?: number
  element?: (item: any) => JSX.Element
  tdStyle?: React.CSSProperties
}

interface SearchParameters {
  [key: string]: string
}

interface DataTableProps {
  onActions?: {
    onClickButtonEdit?: <T>(currentValue: T | any) => void
    onClickButtonRemove?: <T>(currentValue: T | any) => void
    onClickButtonList?: <T>(currentValue: T | any) => void
  }
  entity: string
  source: string
  headers?: Header[]
  customHeaders?: Header[]
  actions?: Action[]
  notHasChildren?: boolean
  onlyParent?: boolean
  notParent?: boolean
  onlyView?: boolean
  hasSearch?: boolean
  parentId?: string
  viewField?: { source: string; field: string }
  entityId?: string
  searchParameters?: SearchParameters[]
  showParentId?: boolean
  totalColumn?: string
  format: {
    orderBy: string
    orderBySort?: 'DESC' | 'ASC'
  }
  excludeColumn?: string
  excludeKey?: string
  activeItems?: number[]
  searchField?: string
}

const DataTable = ({
  onActions,
  entity,
  source,
  notHasChildren,
  viewField,
  onlyView,
  onlyParent,
  notParent,
  excludeColumn,
  hasSearch = true,
  excludeKey,
  headers = [
    { name: 'Data', field: 'created_at', sortable: true },
    { name: 'Descrição', field: 'descriptions', sortable: true },
    { name: 'Ação', field: 'type', sortable: true }
  ],
  actions,
  format,
  parentId,
  entityId,
  searchParameters,
  showParentId,
  searchField,
  customHeaders
}: DataTableProps): JSX.Element => {
  const [items, setItems] = useState<any[]>([])
  const [filterItems, setFilterItems] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [ItemsPerPage, setItemsPerPage] = useState(50)
  const { isUpdated } = useUpdateDataTable()

  const { activeLoading, disableLoading } = useLoading()

  const handlerOnClickButtonList = (currentValue: any) => {
    if (viewField) {
      return `/${viewField.source}/view/${currentValue[viewField.field]}?tab=${
        currentValue.id
      }`
    }

    if (typeof onActions?.onClickButtonList === 'function') {
      onActions.onClickButtonList(currentValue)
    } else {
      return `/${source}/view/${currentValue.id}`
    }
  }

  const handlerOnClickButtonEdit = (currentValue: any) => {
    return `/${source}/update/${currentValue.id}`
  }

  const handlerOnClickButtonRemove = (currentValue: any) => {
    if (onActions?.onClickButtonRemove) {
      onActions.onClickButtonRemove(currentValue)
    }
  }

  const loadParams = useCallback(() => {
    const params = {
      entity,
      source,
      keyword: '',
      page: currentPage,
      perPage: ItemsPerPage,
      orderByField: '',
      orderBySort: format.orderBySort || 'DESC',
      searchParameters,
      onlyParent,
      notParent,
      orderBy: format.orderBy,
      parentId,
      entityId,
      excludeColumn,
      searchField,
      excludeKey
    }

    if (!parentId) Object.assign(params, { parentId: '' })
    if (!entityId) Object.assign(params, { entityId: '' })

    return params
  }, [
    entity,
    source,
    currentPage,
    ItemsPerPage,
    format.orderBySort,
    format.orderBy,
    searchParameters,
    onlyParent,
    notParent,
    parentId,
    entityId,
    excludeColumn,
    searchField,
    excludeKey
  ])

  useEffect(() => {
    ;(async () => {
      try {
        activeLoading()
        const params = loadParams()
        const response = await api.get('dataTable', { params })

        setItems(response.data.items)
        setFilterItems(response.data.items)
        setTotalItems(response.data.totalItens)
        setCurrentPage(response.data.page)
      } catch (error) {
        console.error(error)
      } finally {
        setTimeout(() => {
          disableLoading()
        }, 500)
      }
    })()
  }, [loadParams, isUpdated, activeLoading, disableLoading])

  const firstItem =
    totalItems === 0 ? totalItems : ItemsPerPage * (currentPage - 1) + 1

  const getTotalItems = (initialValue: number): number => {
    let sum = 0
    if (initialValue > 1) {
      return items.length + initialValue - 1
    } else {
      if (notHasChildren) {
        sum = items.reduce((sum, value) => {
          if (!showParentId && !value.parent_id) {
            return sum + 1
          }
          if (showParentId) {
            return sum + 1
          }
          return sum
        }, 0)
      } else {
        sum = items.length
      }
    }
    return sum
  }

  const getSearched = async (value: string) => {
    const params = loadParams()
    const response = await api.get('dataTable', {
      params: {
        ...params,
        keyword: { [params.searchField || params.orderBy]: value }
      }
    })
    setItems(response.data.items)
  }

  let timeOutId: NodeJS.Timeout
  const onSearchItem = async (value: string) => {
    if (value.length === 0) {
      setItems(filterItems)
      clearTimeout(timeOutId)
      return
    }

    clearTimeout(timeOutId)
    timeOutId = setTimeout(() => getSearched(value), 500)
  }

  return (
    <div id="kt_datatable">
      <div className="card-header p-0 align-items-center py-5 gap-2 gap-md-5">
        <div className="">
          <div id="kt_datatable" className="dataTables_length">
            <label style={{ display: 'flex' }}>
              <select
                onChange={e => setItemsPerPage(Number(e.target.value))}
                className="form-select form-select-solid form-select-lg fw-boldl"
                style={{ width: 'auto' }}
              >
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>
              <p
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '1rem'
                }}
              >
                resultados por página
              </p>
            </label>
          </div>
        </div>
        {hasSearch && (
          <div className="">
            <div className="dataTables_filter">
              <label className="fw-bolder fs-6 text-gray-800">
                Pesquisar
                <Search onSearch={value => onSearchItem(value)} />
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="table-scrollable dataTable">
        <table
          id="kt_datatable"
          className="dataTable table table-striped table-row-bordered gs-3 border table-hover"
        >
          <TableHeader
            headers={headers}
            onSorting={(field, order) => {
              const itemSorted = items.sort((a, b) => {
                const fields = field.split('.')
                let currentFieldA
                let currentFieldB
                if (fields.length === 1) {
                  currentFieldA = a[fields[0]]
                  currentFieldB = b[fields[0]]
                }
                if (fields.length === 2) {
                  currentFieldA = a[fields[0]]?.[fields[1]]
                  currentFieldB = b[fields[0]]?.[fields[1]]
                }
                if (fields.length === 3) {
                  currentFieldA = a[fields[0]]?.[fields[1]]?.[fields[2]]
                  currentFieldB = b[fields[0]]?.[fields[1]]?.[fields[2]]
                }
                if (fields.length === 4) {
                  currentFieldA =
                    a[fields[0]]?.[fields[1]]?.[fields[2]]?.[fields[3]]
                  currentFieldB =
                    b[fields[0]]?.[fields[1]]?.[fields[2]]?.[fields[3]]
                }
                if (
                  moment(currentFieldA, 'DD/MM/YYYY hh:mm:ss').isValid() &&
                  moment(currentFieldB, 'DD/MM/YYYY hh:mm:ss').isValid()
                ) {
                  return moment(currentFieldA, 'DD/MM/YYYY hh:mm:ss').isBefore(
                    moment(currentFieldB, 'DD/MM/YYYY hh:mm:ss')
                  ) && order === 'ASC'
                    ? 1
                    : -1
                }
                return currentFieldA > currentFieldB && order === 'ASC' ? 1 : -1
              })
              setItems([...itemSorted])
            }}
          />
          <tbody>
            {(items.length > 0 &&
              items.map(item => {
                return (
                  <tr key={Math.floor(Math.random() * 9999).toString()}>
                    {headers.map(header =>
                      header?.field !== 'actions' && !header.custom ? (
                        <td
                          key={`${header?.field}-${item.id}`}
                          style={header.tdStyle}
                        >
                          <p
                            style={{
                              textAlign: 'left'
                            }}
                          >
                            {typeof item[header?.field] === 'boolean' &&
                              (item[header?.field] ? 'Sim' : 'Nâo')}
                            {header?.field.split('.').length === 3 &&
                              item[header?.field.split('.')[0]]?.[
                                header?.field.split('.')[1]
                              ]?.[header?.field.split('.')[2]]}
                            {header?.field.split('.').length === 2 &&
                              item[header?.field.split('.')[0]]?.[
                                header?.field.split('.')[1]
                              ]}
                            {header?.field.split('.').length === 1 &&
                              item[header?.field]}
                          </p>
                        </td>
                      ) : header.custom ? (
                        <td style={header.tdStyle}>
                          {customHeaders
                            .find(h => h.field === header.field)
                            .element(item)}
                        </td>
                      ) : (
                        <td key={`actions-${item.id}`} className="actions">
                          {(actions && (
                            <LinkContainer>
                              {actions.map(action => (
                                <Link
                                  to={
                                    action.link ||
                                    (action.linkTo && action.linkTo(item)) ||
                                    '#'
                                  }
                                  key={action.name}
                                  title={action.title}
                                  onClick={e => {
                                    if (action.onClick) {
                                      e.preventDefault()
                                      action.onClick(item)
                                    }
                                  }}
                                >
                                  {action.element && action.element(item)}
                                  {action.spanIcon && (
                                    <span className={action.spanIcon} />
                                  )}
                                  {action.icon && (
                                    <FontAwesomeIcon icon={action.icon} />
                                  )}
                                  {action.htmlIcon && action.htmlIcon}
                                </Link>
                              ))}
                            </LinkContainer>
                          )) || (
                            <LinkContainer>
                              {!item.parent_id && (
                                <Link
                                  className="link"
                                  key={Math.random()}
                                  title="Visualizar"
                                  to={() => handlerOnClickButtonList(item)}
                                >
                                  <span className="fa fa-search" />
                                </Link>
                              )}
                              <div>
                                {!onlyView && (
                                  <Link
                                    className="link"
                                    key={Math.random()}
                                    title="Editar"
                                    onClick={e => {
                                      if (onActions?.onClickButtonEdit) {
                                        e.preventDefault()
                                        onActions.onClickButtonEdit(item)
                                      }
                                    }}
                                    to={() => handlerOnClickButtonEdit(item)}
                                  >
                                    <span className="fa fa-edit" />
                                  </Link>
                                )}
                                {!notHasChildren && (
                                  <div
                                    key={Math.random()}
                                    title="Remover"
                                    className="link"
                                    onClick={() => {
                                      handlerOnClickButtonRemove(item)
                                    }}
                                  >
                                    <span className="fa fa-remove" />
                                  </div>
                                )}
                              </div>
                            </LinkContainer>
                          )}
                        </td>
                      )
                    )}
                  </tr>
                )
              })) || (
              <tr>
                <td colSpan={headers.length} style={{ textAlign: 'center' }}>
                  Nenhum registro encontrado
                </td>
              </tr>
            )}
          </tbody>
          <tfoot />
        </table>
      </div>
      <div className="d-flex flex-stack align-items-end h-60px">
        <div className="">
          <div className="dataTables_info" id="kt_datatable">
            Mostrando de {firstItem} até {getTotalItems(firstItem)} de{' '}
            {totalItems} registros
          </div>
        </div>
        <div className="">
          <div className="dataTables_paginate paging_bootstrap_number">
            <Pagination
              total={totalItems}
              itemsPerPage={ItemsPerPage}
              currentPage={currentPage}
              onPageChange={page => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataTable
