export const headers = [
  { name: 'Indicador', field: 'indicator.name', sortable: true },
  { name: 'CPF', field: 'cpf', sortable: true },
  { name: 'Nome do titular', field: 'holder_name', sortable: true },
  { name: 'Cod. Banco', field: 'bank_code', sortable: true },
  { name: 'Agência', field: 'agency', sortable: true },
  { name: 'Conta', field: 'account', sortable: true },
  { name: 'Valor', field: 'value_comission', sortable: false, custom: true },
  { name: 'Valor pago', field: 'paid_value', sortable: false, custom: true },
  { name: 'Pago em', field: 'paid_date', sortable: false, custom: true },
  { name: 'Situação', field: 'situation', sortable: false, custom: true },
  { name: 'Criado em', field: 'created_at', sortable: false, custom: true },
  { name: 'Ações', field: 'actions', sortable: false, custom: true }
]
