import React from 'react'
import ComissionsList from '../../pages/Commercial/Comissions/List'
import PrivateRoute from '../PrivateRoute'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ComissionsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoute
      path="/commercial/comissions"
      exact
      component={ComissionsList}
    />
  </CustomSwitch>
)
