import styled, { css } from 'styled-components'

import Tooltip from '../Tooltip'

export const ContainerCustom = styled.div<{ erro?: boolean }>`
  p.error-checkbox {
    @media (max-width: 1200px) {
      display: none;
    }
    position: absolute;
    margin-top: 50px;
    color: #ff3366;
  }
  input {
    border: ${props => props.erro && '1px solid red !important'};
    color: ${props => (props.erro ? 'red !important' : '#5E6278 !important')};
  }
  label {
    color: ${props => (props.erro ? 'red !important' : '#5E6278 !important')};
  }
  svg {
    margin-right: 16px;
  }
`

export const Contanier = styled.div<{ erro?: boolean }>`
  border-radius: 10px;
  margin-bottom: 1em;

  > label {
    font-weight: normal;
  }

  & > div {
    position: relative;
  }

  input {
    flex: 1;
    background: transparent;
    border: 1px solid #c2cad8;
    padding-right: 30px;
    border: ${props => props.erro && '1px solid red !important'};
    color: ${props => (props.erro ? 'red !important' : '#5E6278 !important')};
    &::placeholder {
      color: #666360;
    }
  }

  p.error {
    @media (max-width: 1200px) {
      display: none;
    }
    position: absolute;
    bottom: -22px;
    color: #ff3366;
  }

  svg {
    margin-right: 16px;
  }
`

export const Error = styled(Tooltip)`
  @media (max-width: 1200px) {
    display: none;
  }
  height: 20px;
  width: fit-content;
  position: absolute;
  right: 20px;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
  svg {
    margin: 0;
  }

  span {
    width: max-content;
    background: #c53030;
    color: #fff;
    transform: translateX(-50%);
    &::before {
      border-color: #c53030 transparent;
    }
    ${props =>
      props.position === 'left' &&
      css`
        transform: translateX(-90%);
        &::before {
          left: 90%;
        }
      `}
  }
`

export const SelectContanier = styled.div<{ erro?: boolean }>`
  border-radius: 10px;
  margin-bottom: 1em;

  > label {
    font-weight: normal;
  }

  select {
    width: 100%;

    border: ${props => props.erro && '1px solid red !important'};
    color: ${props => (props.erro ? 'red !important' : '#5E6278 !important')};

    option:disabled {
      display: none;
    }
  }

  p.error {
    @media (max-width: 1200px) {
      display: none;
    }
    position: absolute;
    margin-top: 80px;
    color: #ff3366;
  }
`

export const TextAreaContanier = styled.div<{ erro?: boolean }>`
  border-radius: 10px;
  margin-bottom: 1em;

  > label {
    font-weight: normal;
  }

  textarea {
    width: 100%;
    padding: 5px;

    border: ${props => props.erro && '1px solid red !important'};
    color: ${props => (props.erro ? 'red !important' : '#5E6278 !important')};

    option:disabled {
      display: none;
    }
  }
  p.error {
    @media (max-width: 1200px) {
      display: none;
    }
    position: absolute;
    margin-top: 12px;
    color: #ff3366;
  }
`
export const DatePickerContainer = styled.div<{ erro?: boolean }>`
  border-radius: 10px;
  margin-bottom: 1em;

  > label {
    font-weight: normal;
  }

  input {
    flex: 1;
    background: transparent;
    border: 1px solid #c2cad8;
    padding-right: 30px;

    &::placeholder {
      color: #666360;
      color: ${props => (props.erro ? 'red !important' : '#5E6278 !important')};
    }
    border: ${props => props.erro && '1px solid red !important'};
    color: ${props => (props.erro ? 'red !important' : '#5E6278 !important')};
  }
  & > div {
    position: relative;
  }

  .react-datepicker,
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 12px;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: 7px;
  }

  p.error {
    @media (max-width: 1200px) {
      display: none;
    }
    position: absolute;
    bottom: -22px;
    color: #ff3366;
  }
`
