import React, { useCallback, useEffect, useState } from 'react'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import Container from '../../../../components/Container'
import { StringParam, useQueryParam } from 'use-query-params'
import Form, { Input, Select } from '../../../../components/Form'
import { Date as DatePicker } from '../../../../components/Form/date'
import {
  CONTRACTS_PAYMENT_METHOD_TRANSLATED,
  CONTRACTS_STATUS_PAYMENT,
  CONTRACTS_STATUS_PAYMENT_TRANSLATED
} from '../../../../common/constants/status-contract'
import { genericMaskWithTwoZeroWithPoint } from '../../../../utils/mask'
import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/Modal'
import { FormEditQuantity } from '../components/FormEditQuantity'
import { FormDownloadManual } from '../components/FormDownloadManul'
import { useUpdateDataTable } from '../../../../hooks/dataTable'
import { FormDocument } from '../components/FormDocument'
import api from '../../../../services/api'
import { Loading } from '../../../../components/Loading'
import moment from 'moment'
import { INPUT_MASK } from '../../../../common/constants'
import { CONTRACTS_PROGRESS_OPTIONS } from '../../../../common/constants/contract-progress'
import { convertBas64ToString } from '../../../../utils/convertToBase64'
import { FormSetIndicator } from '../components/FormSetIndicator'
import { FormSetCommission } from '../components/FormSetCommission'
const ClientList: React.FC = () => {
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [title, setTitle] = useQueryParam('title', StringParam)
  const [status, setStatus] = useQueryParam('status', StringParam)
  const [contractProgress, setContractProgress] = useQueryParam(
    'contract_progress_id',
    StringParam
  )
  const [contractIds, setContractIds] = useQueryParam('ids', StringParam)
  const [date, setDate] = useQueryParam('date', StringParam)
  const [endDateQuery, setEndDateQuery] = useQueryParam('endDate', StringParam)
  const [clearFilter, setClearFilter] = useState<any>()
  const [currentItemUpdate, setCurrentItemUpdate] = useState<any>({} as any)
  const [currentItemSetIndicator, setCurrentItemSetIndicator] = useState<any>(
    {} as any
  )
  const [currentItemSetCommission, setCurrentItemSetCommission] = useState<any>(
    {} as any
  )
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [isLoading, setIsLoading] = useState(true)
  const [modalEdit, setModalEdit] = useState(false)
  const [modalSetIndicator, setModalSetIndicator] = useState(false)
  const [modalSetCommission, setModalSetCommission] = useState(false)
  const [loadingDownloadFile, setLoadingDownloadFile] = useState(false)
  const [modalDownloadPayment, setModalDownloadPayment] = useState(false)
  const [searchParameters, setSearchParameters] = useState<any[]>([])
  const [openModalFolder, setOpenModalFolder] = useState(false)
  const [currentForm, setCurrentForm] = useState<any>()

  const handleSetFilter = useCallback(
    data => {
      setIsLoading(true)
      setSearchParameters([
        { ...data, ids: contractIds ? convertBas64ToString(contractIds) : null }
      ])
      // setStatus('')
      // setContractProgress('')
      // setDate('')
      // setEndDateQuery('')
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    },
    [contractIds]
  )
  const handleClearFilter = useCallback(() => {
    setClearFilter(true)
    setStartDate(null)
    setEndDate(null)
    setStatus('')
    setContractProgress('')
    setSearchParameters([
      { ids: contractIds ? convertBas64ToString(contractIds) : null }
    ])
    setTimeout(() => {
      setClearFilter(false)
    }, 500)
  }, [contractIds, setContractProgress, setStatus])

  const downloadAllFilesContract = useCallback(
    async (item: ContractApiResponse) => {
      setLoadingDownloadFile(true)
      try {
        const response = await api.post(
          `/commercial/contracts/downloadFiles/${item.id}`,
          undefined,
          {
            responseType: 'arraybuffer'
          }
        )
        const blob = new Blob([response.data], {
          type: 'application/octet-stream'
        })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `${item.id}-${item.client?.name}.zip`
        a.click()
      } catch (error) {
        console.log(error, 'error downloading')
      } finally {
        setLoadingDownloadFile(false)
      }
    },
    []
  )

  useEffect(() => {
    setSearchParameters([
      {
        contract_progress_id: contractProgress,
        ids: contractIds ? convertBas64ToString(contractIds) : null,
        status,
        start_date: date,
        end_date: endDateQuery
      }
    ])
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClickOnClose = () => {
    setModalEdit(false)
    setModalSetIndicator(false)
    setModalSetCommission(false)
  }

  const handleClickOnCloseDownloadManual = () => {
    setModalDownloadPayment(false)
  }

  const handleClickOnCloseDocuments = () => {
    setOpenModalFolder(false)
    updateDataTable()
  }

  if (clearFilter) {
    return <Loading isActive />
  }

  return (
    <>
      <Loading isActive={loadingDownloadFile || isLoading} />
      <Container pageTitle={title} breadcrumb={breadcrumbList} tools={[]}>
        <Form
          onSubmit={handleSetFilter}
          defaultValues={{
            status
          }}
        >
          <div className="form">
            <div className="row mb-5">
              <Input
                name="client.name"
                label="Contratante"
                className="col-md-4"
              />
              <Select
                className="col-md-3"
                name="contract_progress_id"
                label="Progresso"
                options={CONTRACTS_PROGRESS_OPTIONS}
                blank
                defaultValue=""
              />
              <Select
                className="col-md-3"
                name="status"
                label="Status de pagamento"
                options={CONTRACTS_STATUS_PAYMENT}
                blank
                defaultValue=""
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <div className="col-md-2 d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-light-primary"
                onClick={handleClearFilter}
              >
                Limpar
              </button>
            </div>
            <div className="col-md-1 d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Filtrar
              </button>
            </div>
          </div>
        </Form>
        {!isLoading && (
          <DataTable
            source={nameSource}
            entity={nameEntity}
            format={{ orderBy: '' }}
            headers={headers}
            notHasChildren
            hasSearch={false}
            actions={[
              // {
              //   name: 'Informações finais',
              //   title: 'Informações finais',
              //   element: item => {
              //     return (
              //       <span
              //         style={{
              //           color: item.release_final_information
              //             ? '#009EF7'
              //             : '#67C7FF',
              //           cursor: item.release_final_information
              //             ? 'pointer'
              //             : 'default',
              //           pointerEvents: item.release_final_information
              //             ? 'all'
              //             : 'none'
              //         }}
              //         className="fa fa-address-card"
              //         onClick={() =>
              //           history.push(
              //             `/commercial/contracts/finalInformation/${item.id}`
              //           )
              //         }
              //       ></span>
              //     )
              //   }
              // },
              {
                name: 'Visualizar',
                title: 'Visualizar',
                spanIcon: 'fa fa-search  fa-lg me-5',
                onClick: (item: ContractApiResponse) => {
                  history.push(`/commercial/contracts/view/${item.id}`)
                }
              },
              // {
              //   name: 'Baixar',
              //   title: 'Baixar',
              //   spanIcon: 'fa fa-download me-5',
              //   onClick: async (item: ContractApiResponse) => {
              //     await downloadAllFilesContract(item)
              //   }
              // },
              {
                name: 'Adicionar indicador',
                title: 'Adicionar indicador',
                onClick: (item: ContractApiResponse) => {
                  if (item.client_indicator_id) {
                    return
                  }
                  setModalSetIndicator(true)
                  setCurrentItemSetIndicator(item)
                },
                element: (item: ContractApiResponse) => {
                  if (item.client_indicator_id) {
                    return (
                      <span
                        className="fa fa-handshake fa-lg me-5 disable"
                        style={{
                          pointerEvents: 'none',
                          opacity: '0.5'
                        }}
                      ></span>
                    )
                  }
                  return <span className="fa fa-handshake fa-lg me-5"></span>
                }
              },
              {
                name: 'Editar comissão',
                title: 'Editar comissão',
                onClick: (item: ContractApiResponse) => {
                  setModalSetCommission(true)
                  setCurrentItemSetCommission(item)
                },
                element: (item: ContractApiResponse) => {
                  return <span className="fa fa-percent fa-lg me-5"></span>
                }
              },
              {
                name: 'Alterar quantidade',
                title: 'Alterar quantidade',
                spanIcon: 'fa-regular fa-edit  fa-lg me-5',
                onClick: (item: ContractApiResponse) => {
                  setModalEdit(true)
                  setCurrentItemUpdate(item)
                }
              },
              {
                name: 'Baixa manual',
                title: 'Baixa manual',
                spanIcon: 'fa-regular fa-dollar  fa-lg me-5',
                onClick: (item: ContractApiResponse) => {
                  if (item.status === 'paid') {
                    return
                  }
                  setModalDownloadPayment(true)
                  setCurrentItemUpdate(item)
                }
              }
            ]}
            searchParameters={searchParameters}
            customHeaders={[
              {
                name: 'Criado em',
                field: 'created_at',
                sortable: false,
                element: item => {
                  return (
                    <p>
                      {moment(item?.created_at).format(
                        INPUT_MASK.DATE_LOCALE_WITH_TIME
                      )}
                    </p>
                  )
                }
              },
              {
                name: 'Info. finais vista em',
                field: 'final_information_read_at',
                sortable: false,
                element: item => {
                  return (
                    <p>
                      {item?.final_information_read_at
                        ? moment(item?.final_information_read_at).format(
                            INPUT_MASK.DATE_LOCALE_WITH_TIME
                          )
                        : ''}
                    </p>
                  )
                }
              },
              {
                name: 'Status',
                field: 'status',
                sortable: true,
                element: (item: ContractApiResponse) => {
                  return (
                    <p>{CONTRACTS_STATUS_PAYMENT_TRANSLATED[item.status]}</p>
                  )
                }
              },
              {
                name: 'Forma de pagamento',
                field: 'payment_form',
                sortable: true,
                element: (item: ContractApiResponse) => {
                  return (
                    <p>
                      {CONTRACTS_PAYMENT_METHOD_TRANSLATED[item.payment_form] ||
                        ''}
                    </p>
                  )
                }
              },
              {
                name: 'Valor',
                field: 'value',
                sortable: true,

                element: (item: { total_value: string }) => {
                  return (
                    <p>{genericMaskWithTwoZeroWithPoint(item.total_value)}</p>
                  )
                }
              },
              {
                name: 'Total Pago',
                field: 'amount_charged',
                sortable: true,

                element: (item: { amount_charged: string }) => {
                  return (
                    <p>
                      {genericMaskWithTwoZeroWithPoint(item.amount_charged)}
                    </p>
                  )
                }
              }
            ]}
          />
        )}
      </Container>

      {modalDownloadPayment && (
        <Modal
          onClickButtonCancel={handleClickOnCloseDownloadManual}
          isOpenModal={modalDownloadPayment}
          pageTitle={`Baixa manual - ${currentItemUpdate?.client?.name}`}
          Children={
            <FormDownloadManual
              typeForm="update"
              initialValues={{
                ...currentItemUpdate,
                idUpdate: currentItemUpdate?.id
              }}
              isOpenInModal={{
                handleOnClose: handleClickOnCloseDownloadManual,
                idParent: currentItemUpdate?.id
              }}
            />
          }
        />
      )}
      {modalSetIndicator && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalSetIndicator}
          pageTitle={'Adicionar indicador'}
          style={{ stylesModal: { maxHeight: '540px', overflow: 'auto' } }}
          Children={
            <FormSetIndicator
              typeForm="update"
              initialValues={{
                ...currentItemSetIndicator,
                idUpdate: currentItemSetIndicator?.id
              }}
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: currentItemSetIndicator?.id
              }}
            />
          }
        />
      )}
      {modalSetCommission && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalSetCommission}
          pageTitle={'Editar comissão'}
          style={{ stylesModal: { maxHeight: '540px', overflow: 'auto' } }}
          Children={
            <FormSetCommission
              typeForm="update"
              initialValues={{
                ...currentItemSetCommission,
                idUpdate: currentItemSetCommission?.idm
              }}
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: currentItemSetCommission?.id
              }}
            />
          }
        />
      )}
      {modalEdit && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalEdit}
          pageTitle={`Editar - ${currentItemUpdate?.client?.name}`}
          style={{ stylesModal: { maxHeight: '540px', overflow: 'auto' } }}
          Children={
            <FormEditQuantity
              typeForm="update"
              initialValues={{
                ...currentItemUpdate,
                idUpdate: currentItemUpdate?.id
              }}
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: currentItemUpdate?.id
              }}
            />
          }
        />
      )}
      {openModalFolder && (
        <Modal
          onClickButtonCancel={handleClickOnCloseDocuments}
          isOpenModal={openModalFolder}
          pageTitle="Documentos"
          style={{
            padding: 0,
            stylesModal: {
              width: '1150px',
              overflow: 'auto'
            }
          }}
          Children={
            <FormDocument
              initialValues={{
                ...currentForm
              }}
              isOpenInModal={{
                handleOnClose: handleClickOnCloseDocuments
              }}
            />
          }
        />
      )}
    </>
  )
}

export default ClientList
